import { Component, OnInit } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import {
  Router,
  NavigationEnd,
  RouterEvent,
  NavigationStart,
} from '@angular/router';
//import { AuthService } from './services';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public settings: Settings;

  constructor(public appSettings: AppSettings, public router: Router, private ccService: NgcCookieConsentService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      }
    });
  }

  navigationInterceptor(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      const url: string = event.url;
      const paths: string[] = url.split('/');

      if (paths.length > 1) {
        const path = paths[1];
        if (path && path === 'cidade') {
          const cidade = paths[2] || 'santa-maria';
          this.router.navigate(['busca-avancada'], {
            queryParams: { location: cidade, sortby: 'd_date' },
          });
        }
      }
    }
  }
}
