<mat-toolbar id="main-toolbar" class="top-toolbar">
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <a class="logo" routerLink="/">
      <img src="../../../../assets/images/logos/cancian-logo-white-22.png">
    </a>
    <div fxShow="false" fxShow.gt-sm class="horizontal-menu" fxLayout="row" fxLayoutAlign="space-between center">
      <!--      
      <a routerLink="/busca-avancada" [queryParams]="queryParamsAniver" mat-raised-button color="warn" fxShow="false" fxShow.gt-xs
        class="uppercase" style="margin-left: 10px;">
       <p style="margin-bottom: 0px;line-height: 1.5;"> Feirão de  <br />Aniversário</p>
      </a>  -->


      <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <!--
<a routerLink="/account/favorites" mat-icon-button matTooltip="Favorites" fxShow="false" fxShow.gt-md>
        <mat-icon [matBadge]="appService.Data.favorites.length" [matBadgeHidden]="appService.Data.favorites.length == 0"
          matBadgeSize="small" matBadgeColor="warn">favorite_border</mat-icon>
      </a>
     -->


      <a routerLink="/comparar" mat-icon-button matTooltip="Lista de Comparação" fxShow="false" fxShow.gt-md
        class="mr-3 ml-2">
        <mat-icon [matBadge]="appService.Data.compareList.length"
          [matBadgeHidden]="appService.Data.compareList.length == 0" matBadgeSize="small" matBadgeColor="warn">
          compare_arrows</mat-icon>
      </a>


      <span fxHide="false" fxHide.gt-sm>
        <button mat-button (click)="sidenavToggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </span>
    </div>
  </div>
</mat-toolbar>