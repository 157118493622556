<mat-sidenav-container>
    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        

        <div perfectScrollbar class="vertical-menu-wrapper">
            
            <app-vertical-menu [menuParentId]="0" [sidenav]="sidenav"></app-vertical-menu>
        </div>

    </mat-sidenav>

    <mat-sidenav-content class="page-wrapper section default" fxLayout="column" fxLayoutAlign="start stretch" style="padding: 0px !important; margin: 0px !important">

        <header class="toolbar-0" [class.has-bg-image]="settings.headerBgImage" [class.main-toolbar-fixed]="true">

            <app-toolbar1 (onMenuIconClick)="this.sidenav.toggle()"></app-toolbar1>

        </header>

        <main [class.content-offset-to-top]="true" [class.margin-top]="settings.mainToolbarFixed">
            <router-outlet></router-outlet>
        </main>

        <div class="sticky-footer-helper" fxFlex="grow"></div>


        <app-footer *ngIf="settings.showFooter"></app-footer>

        <!-- <div fxLayout="row" fxLayoutAlign="center center" class="options-icon" (click)="options.toggle()">
            <mat-icon>settings</mat-icon>
        </div> -->

        <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top"
            (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div>


        <a class="whatsapp-button"
        href="https://wa.me/555530337400"
        target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
        </svg>
        <p>Já sou cliente</p>
        </a>
    </mat-sidenav-content>
<!-- 
    <mat-sidenav #options position="end" class="options" autoFocus="false">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="op-header mat-elevation-z1">
            <h2>Settings</h2>
            <button mat-icon-button (click)="options.toggle()" color="accent">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div perfectScrollbar>
            <div fxLayout="column" class="control">
                <h4>Layout</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Sticky Menu Toolbar</span>
                    <mat-slide-toggle [checked]="settings.stickyMenuToolbar"
                        (change)="settings.stickyMenuToolbar = !settings.stickyMenuToolbar" labelPosition="before">
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>RTL</span>
                    <mat-slide-toggle [checked]="settings.rtl" (change)="settings.rtl = !settings.rtl"
                        labelPosition="before"></mat-slide-toggle>
                </div>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose toolbar type</h4>
                <mat-radio-group [(ngModel)]="toolbarTypeOption" (change)="chooseToolbarType()">
                    <mat-radio-button *ngFor="let toolbarType of toolbarTypes" [value]="toolbarType">
                        toolbar-{{toolbarType}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose header type</h4>
                <mat-radio-group [(ngModel)]="headerTypeOption" (change)="chooseHeaderType()">
                    <mat-radio-button *ngFor="let headerType of headerTypes" [value]="headerType">{{headerType}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose theme skin</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="skin-primary blue" (click)="changeTheme('blue')"></div>
                    <div class="skin-primary green" (click)="changeTheme('green')"></div>
                    <div class="skin-primary red" (click)="changeTheme('red')"></div>
                    <div class="skin-primary pink" (click)="changeTheme('pink')"></div>
                    <div class="skin-primary purple" (click)="changeTheme('purple')"></div>
                    <div class="skin-primary grey" (click)="changeTheme('grey')"></div>
                    <div class="skin-primary orange-dark" (click)="changeTheme('orange-dark')">
                        <div class="skin-secondary"></div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose search panel variant</h4>
                <mat-radio-group [(ngModel)]="searchPanelVariantOption" (change)="chooseSearchPanelVariant()">
                    <mat-radio-button *ngFor="let searchPanelVariant of searchPanelVariants"
                        [value]="searchPanelVariant">variant-{{searchPanelVariant}}</mat-radio-button>
                </mat-radio-group>
                <mat-slide-toggle [checked]="settings.searchOnBtnClick"
                    (change)="settings.searchOnBtnClick = !settings.searchOnBtnClick" labelPosition="after">Search On
                    Button Click</mat-slide-toggle>
            </div>

        </div>
    </mat-sidenav> -->

</mat-sidenav-container>