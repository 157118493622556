import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AgmCoreModule } from '@agm/core';
//import { EmbedVideo } from 'ngx-embed-video';
import { InputFileConfig, InputFileModule } from 'ngx-input-file';
const config: InputFileConfig = {
   fileAccept: '*'
};

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AppRoutingModule } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { AppInterceptor } from './theme/utils/app-interceptor';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
//import { Toolbar2Component } from './theme/components/toolbar2/toolbar2.component';
//import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
///import { CurrencyComponent } from './theme/components/currency/currency.component';
import { LangComponent } from './theme/components/lang/lang.component';
import { SocialIconsComponent } from './theme/components/social-icons/social-icons.component';
import { ContactsComponent } from './theme/components/contacts/contacts.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { GtagModule } from 'angular-gtag';
import { GalleryModule } from 'ng-gallery';
import { GALLERY_CONFIG } from 'ng-gallery';

import { RedirectGuard } from './guards/RedirectGuard';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from './config/cookie.config';

registerLocaleData(ptBr);
//import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';

@NgModule({
   declarations: [
      AppComponent,
      PagesComponent,
      NotFoundComponent,
      //UserMenuComponent,
      //CurrencyComponent,
      LangComponent,
      SocialIconsComponent,
      ContactsComponent,
      Toolbar1Component,
      //Toolbar2Component,
      HorizontalMenuComponent,
      VerticalMenuComponent,
      FooterComponent
      //LockScreenComponent
   ],
   imports: [
      NgcCookieConsentModule.forRoot(cookieConfig),
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      AgmCoreModule.forRoot({
         apiKey: 'AIzaSyCih4iTaq5A2XMnkCxuKFAfbJUPeOvKp28',
         libraries: ['places']
      }),
      //  EmbedVideo.forRoot(),
      NgProgressModule,
      NgProgressHttpModule,
      InputFileModule.forRoot(config),
      AppRoutingModule,
      SharedModule,
      GtagModule.forRoot({
         trackingId: 'UA-198149029-1',
         trackPageviews: true
      }),
      GalleryModule
   ],
   providers: [
      AppSettings,
      { provide: OverlayContainer, useClass: CustomOverlayContainer },
      { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
      {
         provide: LOCALE_ID,
         useValue: 'pt'
      },
      {
         provide: GALLERY_CONFIG,
         useValue: {
            dots: false,
            imageSize: 'contain'
         }
      },
      RedirectGuard
   ],
   bootstrap: [AppComponent],
   schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
