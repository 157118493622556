import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
   wheelPropagation: false,
   suppressScrollX: true
};

import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module';

import { HeaderImageComponent } from './header-image/header-image.component';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { PropertyItemComponent } from './property-item/property-item.component';
import { PropertyItemSkeletonComponent } from './property-item-skeleton/property-item-skeleton.component';
import { LoadMoreComponent } from './load-more/load-more.component';
import { PropertiesToolbarComponent } from './properties-toolbar/properties-toolbar.component';
import { PropertiesSearchComponent } from './properties-search/properties-search.component';
import { CompareOverviewComponent } from './compare-overview/compare-overview.component';
import { RatingComponent } from './rating/rating.component';
import { PropertiesSearchResultsFiltersComponent } from './properties-search-results-filters/properties-search-results-filters.component';
import { PropertiesCarouselComponent } from './properties-carousel/properties-carousel.component';
import { ClientsComponent } from './clients/clients.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { CommentsComponent } from './comments/comments.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { OurAgentsComponent } from './our-agents/our-agents.component';
import { MissionComponent } from './mission/mission.component';
import { CondoCardComponent } from './condo-card/condo-card.component';
import { AluguelExpressComponent } from './aluguel-express/aluguel-express.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { LogoComponent } from './logo/logo.component';
import { SohtecBtnComponent } from './sohtec-btn/sohtec-btn.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AluguelTotalPipe } from '../../pipes/aluguel-total.pipe';
import { TypeofPipe } from '../../pipes/typeof.pipe';
import { ParsefloatPipe } from '../../pipes/parsefloat.pipe';
import { SplitPipe } from '../../pipes/split.pipe';
import { LabelColorPipe } from '../../pipes/label-color.pipe';
import { AreaMuxPipe  } from '../../pipes/area-mux.pipe';
import { LabelsPipe } from '../../pipes/labels.pipe';
import { FormattedAddressPipe } from '../../pipes/formattedAddress.pipe';
import { UrlEmbedVideoPipe } from '../../pipes/urlEmbedVideo.pipe';
import { SanityzerURLPipe } from '../../pipes/sanityzerURL.pipe';
import { NouisliderModule } from 'ng2-nouislider';
import { CicleProgressComponent } from './cicle-progress/cicle-progress.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { KitnetComponent } from './kitnet/kitnet.component';
const maskConfig: Partial<IConfig> = {
   validation: false
};
@NgModule({
   imports: [
      NgxSliderModule,
      CommonModule,
      RouterModule,
      ReactiveFormsModule,
      SwiperModule,
      FlexLayoutModule,
      MatAutocompleteModule,
      MatBadgeModule,
      MatBottomSheetModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatSortModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      MatStepperModule,
      PerfectScrollbarModule,
      PipesModule,
      DirectivesModule,
      NouisliderModule,
      NgxMaskModule.forRoot(maskConfig)
   ],
   exports: [
      SohtecBtnComponent,
      RouterModule,
      ReactiveFormsModule,
      SwiperModule,
      FlexLayoutModule,
      MatAutocompleteModule,
      MatBadgeModule,
      MatBottomSheetModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatSortModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      MatStepperModule,
      PerfectScrollbarModule,
      PipesModule,
      DirectivesModule,
      LogoComponent,
      HeaderImageComponent,
      HeaderCarouselComponent,
      PropertyItemComponent,
      CondoCardComponent,
      PropertyItemSkeletonComponent,
      LoadMoreComponent,
      PropertiesToolbarComponent,
      PropertiesSearchComponent,
      CompareOverviewComponent,
      RatingComponent,
      CicleProgressComponent,
      PropertiesSearchResultsFiltersComponent,
      PropertiesCarouselComponent,
      ClientsComponent,
      GetInTouchComponent,
      CommentsComponent,
      TestimonialsComponent,
      OurAgentsComponent,
      MissionComponent,
      AluguelExpressComponent,
      OurServicesComponent,
      KitnetComponent,
      AluguelTotalPipe,
      TypeofPipe,
      ParsefloatPipe,
      SplitPipe,
      LabelColorPipe,
      LabelsPipe,
      FormattedAddressPipe,
      UrlEmbedVideoPipe,
      SanityzerURLPipe,
      AreaMuxPipe
   ],
   declarations: [
      SohtecBtnComponent,
      AluguelExpressComponent,
      LogoComponent,
      HeaderImageComponent,
      HeaderCarouselComponent,
      PropertyItemComponent,
      PropertyItemSkeletonComponent,
      LoadMoreComponent,
      PropertiesToolbarComponent,
      PropertiesSearchComponent,
      CompareOverviewComponent,
      RatingComponent,
      CicleProgressComponent,
      KitnetComponent,
      PropertiesSearchResultsFiltersComponent,
      PropertiesCarouselComponent,
      ClientsComponent,
      GetInTouchComponent,
      CommentsComponent,
      TestimonialsComponent,
      OurAgentsComponent,
      MissionComponent,
      CondoCardComponent,
      OurServicesComponent,
      AluguelTotalPipe,
      TypeofPipe,
      ParsefloatPipe,
      SplitPipe,
      LabelColorPipe,
      LabelsPipe,
      FormattedAddressPipe,
      UrlEmbedVideoPipe,
      SanityzerURLPipe,
      AreaMuxPipe
   ],
   entryComponents: [CompareOverviewComponent],
   providers: [
      {
         provide: PERFECT_SCROLLBAR_CONFIG,
         useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      }
   ],
   bootstrap: [SohtecBtnComponent],
   schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
