import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RedirectGuard } from './guards/RedirectGuard';
//import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';

function redirectBairros(path: string) {}

export const routes: Routes = [
   {
      path: '',
      component: PagesComponent,
      children: [
         //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
         {
            path: '',
            loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule)
         },
         {
            path: 'home',
            canActivate: [RedirectGuard],
            component: RedirectGuard,
            data: {
               externalUrl: 'https://cancianimoveis.com.br'
            }
         },
         {
            path: 'sobre',
            loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutModule)
         },
         // {
         //    path: 'sobre',
         //    canActivate: [RedirectGuard],
         //    component: RedirectGuard,
         //    data: {
         //       externalUrl: 'https://cancianimoveis.com.br/sobre'
         //    }
         // },
         {
            path: 'contato',
            loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule)
         },
         // {
         //    path: 'contato',
         //    canActivate: [RedirectGuard],
         //    component: RedirectGuard,
         //    data: {
         //       externalUrl: 'https://cancianimoveis.com.br/contato'
         //    }
         // },
         {
            path: 'busca-avancada',
            loadChildren: () => import('./pages/properties/properties.module').then((m) => m.PropertiesModule)
         },
         {
            path: 'imoveis',
            loadChildren: () =>
               import('./pages/properties-not-search/properties-not-search.module').then(
                  (m) => m.PropertiesNotSearchModule
               )
         },
         {
            path: 'negociacao/venda',

            redirectTo: '/busca-avancada?contrato=Compra&cidade=Santa%20Maria&&sortby=d_date'
         },
         {
            path: 'negociacao/aluguel',
            redirectTo: '/busca-avancada?contrato=Locação&cidade=Santa%20Maria&&sortby=d_date'
         },
         {
            path: 'label/perto-da-ufsm',
            redirectTo: '/busca-avancada?lat=-29.710717&long=-53.7187957&radius=1.2&sortby=d_date'
         },
         {
            path: 'cidade/sao-borja',
            redirectTo: '/busca-avancada?cidade=São Borja&sortby=d_date'
         },
         {
            path: 'property_type/kitnet',
            redirectTo: '/busca-avancada?contrato=Locação&cidade=Santa%20Maria&subtipo=Kitnet&&sortby=d_date'
         },
         {
            path: 'bairros/camobi',
            redirectTo: '/busca-avancada?contrato=Compra&cidade=Santa%20Maria&bairros=Camobi&&sortby=d_date'
         },
         {
            path: 'imovel/:id',
            loadChildren: () => import('./pages/property/property.module').then((m) => m.PropertyModule)
         },
         //{ path: 'agents', loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule) },
         {
            path: 'comparar',
            loadChildren: () => import('./pages/compare/compare.module').then((m) => m.CompareModule)
         },

         // PÁGINA DO CONDOMÍNIO
         {
            path: 'condominio/:id',
            loadChildren: () => import('./pages/condominio/condominio.module').then((m) => m.CondominioModule)
         },

         // PÁGINA DO EMPREENDIMENTO
         {
            path: 'empreendimento/:id',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule)
         },

         // PÁGINA DO EMPREENDIMENTO
         {
            path: 'real-park',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'real-park'
            }
         },
         {
            path: 'livty',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'livty'
            }
         },
         {
            path: 'uniqo-smart-living',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'uniqo-smart-living'
            }
         },
         {
            path: 'vitta-center',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'vitta-center'
            }
         },
         {
            path: 'franciscano',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'franciscano'
            }
         },
         {
            path: 'estancia-dos-montes',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'estancia-dos-montes-home-resort-fase-ii'
            }
         },
         {
            path: 'macerata',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'macerata-residencial'
            }
         },
         {
            path: 'montebello',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'residencial-montebello-v'
            }
         },
         // PÁGINA DO EMPREENDIMENTO
         {
            path: 'la-spezia',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'residencial-la-spezia'
            }
         },
         // PÁGINA DO EMPREENDIMENTO
         {
            path: 'morada-do-leste',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'residencial-morada-do-leste'
            }
         },

         // PÁGINA DO EMPREENDIMENTO
         {
            path: 'vetraz',
            loadChildren: () =>
               import('./pages/empreendimento/empreendimento.module').then((m) => m.EmpreendimentoModule),
            data: {
               id: 'residencial-vetraz'
            }
         },
         //{ path: 'pricing', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },
         {
            path: 'faq',
            loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule)
         },
         {
            path: 'area-do-cliente',
            loadChildren: () =>
               import('./pages/area-cliente/area-cliente.module').then((m) => m.AreaClienteComponentModule)
         },
         {
            path: 'diferenciais-venda',
            loadChildren: () =>
               import('./pages/diferencial-vendas/diferencial-vendas.module').then(
                  (m) => m.DiferencialVendasComponentModule
               )
         },

         //{ path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
         //{ path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
         // { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
         //{ path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
         /*  {
        path: 'submit-property',
        loadChildren: () =>
          import('./pages/submit-property/submit-property.module').then(
            (m) => m.SubmitPropertyModule
          ),
      }*/ {
            path: 'financiamento',
            loadChildren: () =>
               import('./pages/financiamento/financiamento.module').then((m) => m.FinanciamentoComponentModule)
         },
         {
            path: 'garantia-locaticias',
            loadChildren: () =>
               import('./pages/garantia-locaticia/garantia-locaticias.module').then(
                  (m) => m.GarantiaLocaticiasComponentModule
               )
         },
         {
            path: 'venda-alugue-imovel',
            loadChildren: () =>
               import('./pages/venda-alugue-imovel/venda-alugue-imovel.module').then(
                  (m) => m.VendaAlugueImovelComponentModule
               )
         },
         {
            path: 'venda-seu-imovel',
            redirectTo: 'venda-alugue-imovel'
         },
         {
            path: 'mapa-imoveis',
            loadChildren: () => import('./pages/mapa-imovel/mapa-imovel.module').then((m) => m.MapaImovelModule)
         }
      ]
   },
   // { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
   //  { path: 'lock-screen', component: LockScreenComponent },
   { path: '**', component: NotFoundComponent }
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes, {
         //    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
         // useHash: true
      })
   ],
   exports: [RouterModule]
})
export class AppRoutingModule {}
