import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
      domain: window.location.hostname
    },
    palette: {
      popup: {
        background: 'rgba(0, 0, 0, 0.82)'
      },
      button: {
        background: '#43f372'
      }
    },
    theme: 'classic',
    type: 'opt-out',
    revokable: true,   
    
    content : {
      message: 'Nós usamos cookies para melhorar a sua experiência de navegação em nosso site. Ao clicar em "Aceitar" você concorda com o uso de cookies em nosso site.',
      allow  : 'Aceito',
      deny   : 'Rejeitar',
    },
    layout: 'my-custom-layout',
    layouts: {
      "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements:{
      messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}}</span>
      `,
    },
  };