import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
   providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
   constructor(private router: Router) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      window.location.href = route.data['externalUrl'];
      return true;
   }
}
